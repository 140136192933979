@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* *{
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

body{
  background: #f5f6fa;
  
}

#line {
  background-color:  rgb(5, 68, 104);
  width: 50%;
  font-weight: 600;
}



@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.infocontainer {
  display:inline-block;
}
#welcome {
  animation: fadeIn 3s;
}




#myinfo {
  font-family: sans-serif;
  font-size: x-large;
  font-weight: 550;
  line-height: 175%;
  animation: fadeIn 3s forwards;
  opacity: 0;
  animation-delay: 0.5s;
}

#aboutinfo {
  margin-top: 25px;
  font-family: sans-serif;
  font-size: x-large;
  font-weight: 550;
  line-height: 155%;
}

#home,#aboutcontainer,#projectscontainer {
  min-width: 1000px;
}
 #homerow,#aboutrow,#projectsrow {
  min-width: 1000px;
}



/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(5, 68, 104); }
}
#keywordcloud {
  width:400px;
  height:400px;
  border-radius: 20%;
}

@media only screen and (min-width: 992px) {
  #keywordcloud {
    margin-top: 25%;
  }
}


#photo{
  width: 297px;
  height: 270px;
  border-radius: 20%;
}

.wrapper .sidebar{
  background: rgb(5, 68, 104);
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
}

.wrapper .sidebar .profile{
  margin-bottom: 30px;
  text-align: center;
}

.wrapper .sidebar .profile img{
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
}

.wrapper .sidebar .profile h3{
  color: #ffffff;
  margin: 10px 0 5px;
}

.wrapper .sidebar .profile p{
  color: rgb(206, 240, 253);
  font-size: 14px;
}

.wrapper .sidebar ul li {

}
.wrapper .sidebar ul li a{
  display: block;
  height: 70px;
  color: rgb(241, 237, 237);
  position: relative;
}

.wrapper .sidebar ul li a .icon{
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}

 .wrapper .sidebar ul {
  list-style-type: none; 
  padding: 0;
 }

.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active{
  color: #0c7db1;
  background-color: white;
}

.wrapper .sidebar ul li a:hover .icon,
.wrapper .sidebar ul li a.active .icon{
  color: #0c7db1;
}

.wrapper .sidebar ul li a:hover:before,
.wrapper .sidebar ul li a.active:before{
  display: block;
}

.wrapper .section{
  width: calc(100% - 225px);
  margin-left: 230px;
  transition: all 0.5s ease;
}

.wrapper .sidebar .item {
  font-size: 28px;
}
.wrapper .sidebar .subitem {
  font-size: 10px;

}
.wrapper .section .top_navbar{
  background: rgb(5, 68, 104);
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
 
}

.wrapper .section .top_navbar .hamburger a{
  font-size: 28px;
  color: #f4fbff;
}

.wrapper .section .top_navbar .hamburger a:hover{
  color: #a2ecff;
}

 .wrapper .section .container{
  margin: 30px;
  background: #fff;
  padding: 50px;
  line-height: 28px;
}

body.active .wrapper .sidebar{
  left: -225px;
}

body.active .wrapper .section{
  margin-left: 0;
  width: 100%;
}

.wrapper .sidebar ul li p {
  padding: 0;
  margin: 0;
}
.flip_H {
  transform: rotateY(180deg);
}

#leftIndent {
  text-indent: 150px;
}

#bigText {
  font-size: 48px;
}

#firstline {
  font-family: sans-serif;
  font-size: x-large;
  font-weight: 600;
}

.scrolling-wrapper{
	overflow-x: auto;
}

.card-block{
	height: 150px;
  width: 150px;
	background-color: black;
	border: none;
	background-position: center;
	background-size: cover;
	transition: all 0.2s ease-in-out !important;
	border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sihicon {
  background-color: black;
}

.icons {
  border-radius: 24px;
  padding: 5px;
}



.container-lg {
  background-color: yellow;
  border-radius: 30px;
}

#vizierprototype
{
  height: 300px;
  width:200px;
  align: middle;
}

.screenshots
{
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-transform: translateZ(0);
  white-space: nowrap;
  width:100%;

}

.topcomponent {
 display: flex;
 justify-content: center;
}

.logo {
  display: flex;
  flex: 1;
  padding-left: 5px;
  justify-content: center;
}

#footer {
  position: absolute;
  top:90%;
  width: 100%;
}



.closeBtn {
  position: absolute;
  left: 90%;
  top:0;
  z-index: 1;
  height: 100px;
  width: 100px;
  text-align: center;
  font-size: 100px;  
  background-color: transparent;
  border-color: transparent;
}

.modal {
  z-index:2;
  width:100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: absolute;
  background-color: #fefefe;
  margin: 0;
  top: 10%;
  left: 10%;
  overflow:auto;
  border:1px solid #888;
  height:450px;
  border-radius: 2em;
  width:84%;
  min-width: 800px;
}


@media screen and (max-width: 735px) {

  .modal-content {
    height: 500px;
    top:20%;
  }

  .card-block {
    height: 100px;
    width: 100px;
  }

  .tab
  {
    display: none;
  }
  .tabright
  {
    display: none;
  }
  .screenshots
  {
    display: inline-block;
    overflow:auto;
    white-space: nowrap;
    width:100%;

  }

  #screenshot {
    width:200px;
    height:300px;
  }

  #screenshot2 {
    width:200px;
    height:300px;
    padding:0 10px;
  }

  .btn-close {
    background-color: blue;
    color: #0c7db1;
  }

  .tabcontent {
    padding-top:20px;
    overflow-y: auto;
    color: white;
    height: 400px;
    width:100%;
  }
  #contactsicons
  {
    background-color: green;
    color: tomato;
  }
  .icons
  {
    height: 165px;
    width: 165px;
    border-radius: 25%;

  }
  .sidebar button {
    height: 75px;
    width:75px;
    text-align: center;
  }
  #vizi {
    height:50px;
    width:50px;
  }
  #icon {
    height:160px;
    width:160px;
  }
  #content {
    font-size: 5vw;
  }
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: lightblue;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.companyContainer {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.companyContainer::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: white;
  border: 4px solid #FF9F55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -13px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}



/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
  left: 31px;
  }
  
  /* Full-width containers */
  .companyContainer {
  width: 100%;
  padding-left: 70px;
  padding-right: 25px;
  }
  
  /* Make sure that all arrows are pointing leftwards */
  .companyContainer::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
  left: 15px;
  }
  
  /* Make all right containers behave like the left ones */
  .right {
  left: 0%;
  }
}
